import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layout/layout';
import { Container } from 'reactstrap';
import { TextBlock } from '../../../components/shared/text-block';
import { PRIMARY_TEXT_COLOR } from '../../../constants';
import { Wrapper } from '../../../components/shared/wrapper';
import { Fullscreen } from '../../../components/fullscreen/fullscreen';
import { Background } from '../../../components/background/background';
import cover from '../../../../static/images/services/cover.png';
import { BadgeLanguage, TitleContainer, TitleText } from '../../../components/shared';
import Link from '../../../components/shared/link';
import * as QueryString from 'query-string';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 370px) {
        flex-direction: column;
    }
    justify-content: start;
    align-items: start;
`;

const QualityAssurance = props => {
    const [state, setState] = useState(true);

    useEffect(() => {
        let parsed = QueryString.parse(props.location.search);
        if (parsed.language === 'sl') {
            setState(false);
        }
    }, [props.location.search]);

    const changeLanguage = language => {
        if (state !== language) {
            window.location = `${window.location.pathname}?language=${language ? 'eng' : 'sl'}`;
        }
    };
    return (
        <Layout>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <Background bg={cover} design={'cover'} index={1} />
                <TitleContainer>
                    <TitleText>{state ? 'QA Tester' : 'QA Tester'}</TitleText>
                </TitleContainer>
            </Fullscreen>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <BadgeLanguage>
                        <span className={state ? 'black' : null} onClick={() => changeLanguage(true)}>
                            Eng
                        </span>
                        <span className={state ? null : 'black'} onClick={() => changeLanguage(false)}>
                            Slo
                        </span>
                    </BadgeLanguage>
                    {state ? (
                        <>
                            <TextBlock title={''}>
                                <p>
                                    We are looking for a proactive, curious and responsible coworker to join our team in Maribor, Slovenia.
                                </p>
                            </TextBlock>
                            <TextBlock title={'Your challenges'}>
                                <ul>
                                    <li>Own the test preparation, pipe cleaning, automation of test cases, and the execution.</li>
                                    <li>Execute the functional test pack (mostly automated & manual for smoke testing).</li>
                                    <li>Report to the QA Lead in the generation of test plans, scripts, and procedures.</li>
                                    <li>
                                        Identify, report defects, and ensure the critical defects are fixed before the official release.
                                    </li>
                                    <li>
                                        Work with the business and program stakeholders to ensure that the products perform per
                                        specification.
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Your profile'}>
                                <ul>
                                    <li>You have a 3+ years of experience in the software testing life cycle.</li>
                                    <li>
                                        You have good experience in any of the automation frameworks like Robot, Selenium WebDriver, Rest
                                        Assured, or anything equivalent.
                                    </li>
                                    <li>
                                        You have hands-on programming experience in any of the programming languages (e.g., Python, Java, or
                                        .NET).
                                    </li>
                                    <li>You have experience working in GIT commands through the command line.</li>
                                    <li>You have experience working in a Linux environment and comfortable with UNIX commands.</li>
                                    <li>
                                        You have working experience in an environment which includes tools like JIRA, Confluence, HP Quality
                                        Centre, TestRail, or similar test management tools.
                                    </li>
                                    <li>Experience with dealing with formal requirements.</li>
                                    <li>You have good communication skills (written & verbal).</li>
                                    <li>You are proactive and take ownership from start until closure.</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'What we offer'}>
                                <ul>
                                    <li>Work in a connected and highly qualified team</li>
                                    <li>
                                        Employment Contract for an indefinite period (6 months of probation period)
                                    </li>
                                    <li>Competitive salary, suitable to experiences</li>
                                    <li>
                                        Work on interesting and recognizable projects (NKBM - mBanka@Net, MindCandy - Moshi Twilight,
                                        Autistica - Molehill Mountain app and others)
                                    </li>
                                    <li>A dynamic and relaxed work environment</li>
                                    <li>Flexible working hours and work from home</li>
                                    <li>Further learning and support from the team</li>
                                    <li>Sports activities (good posture)</li>
                                    <li>
                                        Participation in{' '}
                                        <a href={'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners/?guccounter=1'} rel={"nofollow"}>
                                            hackathons.
                                        </a>
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Are you interested?'}>
                                <p>If the position interest you, make sure to apply as soon as possible!</p>
                                <ButtonWrapper>
                                    <Link to="https://forms.office.com/r/q5eimj2HPE" className="mt-2">
                                        <button className="btn btn-warning mr-2">Apply</button>
                                    </Link>

                                    <Link to="https://forms.office.com/r/NQ4BBQ084M" className="mt-2">
                                        <button className="btn btn-link">I want to know more</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    ) : (
                        <>
                            <TextBlock title={''}>
                                <p>
                                    V našo ekipo vabimo proaktivno, radovedno in odgovorno osebo, ki je vajena ekipnega dela in se ne boji
                                    izzivov. Delo poteka na sedežu podjetja v centru Maribora, z možnostjo občasnega dela na daljavo.
                                </p>
                            </TextBlock>
                            <TextBlock title={'Tvoji izzivi'}>
                                <ul>
                                    <li>Priprava testov, “pipe cleaning”, avtomatizacija in izvedba testnih primerov.</li>
                                    <li>Izvedba funkcionalnih testov (večinoma avtomatiziranih in tudi ročnih “smoke” testov).</li>
                                    <li>Poročanje vodji QA oddelka pri pripravi testnih načrtov, skript in postopkov.</li>
                                    <li>
                                        Ugotavljanje in prijava napak ter zagotovitev, da so kritične napake odpravljene pred uradno objavo.
                                    </li>
                                    <li>
                                        Sodelovanje s celotno projektno ekipo, za zagotovitev, da izdelki delujejo v skladu s
                                        specifikacijami.
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Tvoj profil'}>
                                <ul>
                                    <li>Imaš 3+ leta izkušenj v testiranju programske opreme.</li>
                                    <li>
                                        Imaš dobre izkušnje s katerimkoli sistemom za avtomatizacijo, kot so Robot, Selenium WebDriver, Rest
                                        Assured ali kaj podobnega.
                                    </li>
                                    <li>
                                        Imaš praktične izkušnje s programiranjem v kateremkoli programskem jeziku (npr. Python, Java ali
                                        .NET).
                                    </li>
                                    <li>Imaš izkušnje z delom z GIT ukazi preko ukazne vrstice.</li>
                                    <li>Imaš izkušnje z delom v okolju Linux in ukazi UNIX.</li>
                                    <li>
                                        Imaš delovne izkušnje z orodji, kot so JIRA, Confluence, HP Quality Center, TestRail ali podobna
                                        orodja za upravljanje testov.
                                    </li>
                                    <li>Izkušnje z obravnavo formalnih zahtev.</li>
                                    <li>Imaš dobre komunikacijske spretnosti v slovenščini in angleščini (pisne in ustne).</li>
                                    <li>Si proaktiven in prevzameš odgovornost od začetka do konca.</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Nudimo ti'}>
                                <ul>
                                    <li>Delo v povezani in visoko usposobljenosti ekipi</li>
                                    <li>Poskusna doba 6 mesecev - zaposlitev za nedoločen čas</li>
                                    <li>Konkurenčna plača, primerna izkušnjam</li>
                                    <li>
                                        Delo na zanimivih in prepoznavnih projektih (NKBM - mBanka@Net, MindCandy - Moshi Twilight,
                                        Autistica - Molehill Mountain app in drugi)
                                    </li>
                                    <li>Dinamično in sproščeno delovno okolje</li>
                                    <li>Fleksibilni delovni čas in delo od doma</li>
                                    <li>Spodbujanje nadaljnjega učenja</li>
                                    <li>Športne dejavnosti (telovadba za boljšo držo)</li>
                                    <li>
                                        Sodelovanje na{' '}
                                        <a href={'https://techcrunch.com/2015/12/06/disrupt-london-2015-hackathon-winners/?guccounter=1'} rel={"nofollow"}>
                                            hackathonih.
                                        </a>
                                    </li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Te zanima?'}>
                                <p>V kolikor se prepoznate v zgornjem opisu vas vabimo, da se čimprej prijavite!</p>
                                <ButtonWrapper>
                                    <Link to="https://forms.office.com/r/peaTBbv844" className="mt-2">
                                        <button className="btn btn-warning mr-2">Prijava</button>
                                    </Link>
                                    <Link to="https://forms.office.com/r/SzdrNdTctz" className="mt-2">
                                        <button className="btn btn-link">Zanima me več</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    )}
                </Container>
            </Wrapper>
        </Layout>
    );
};

export default QualityAssurance;
